.App {
  text-align: center;
}

/* NavBar */
.navbar{
  background-color: rgb(72, 0, 188);
  
}

.navbar-name{
  color: rgb(246, 246, 246);
  text-decoration: none;
  font-size: 80px;
  font-family: Georgia, 'Times New Roman', Times, serif;
  font-weight: 700;
  padding-left: 20px;
 
  
}

.navbar-left {
  display: flex;
  justify-content: flex-start;
}

@media (max-width: 768px) {
  .navbar-name {
    font-size: 28px;
  }
}

@media (max-width: 768px) {
  .navbar {
    height: 40px;
    width: 100%;
    padding-top: 10px;
   
  }
}




/* hero image*/
.hero-container {
  display: grid;
  grid-template-columns: auto 1fr; 
  align-items: center;
   
}

.face {
  width: 600px; 
  height: auto; 
}

.me-title{
  font-family:Georgia, 'Times New Roman', Times, serif;
  color:rgb(56, 59, 255);
  font-size: 70px;
  font-weight: bolder;
  text-decoration: underline;
  
}

.me-title2{
  font-family:Georgia, 'Times New Roman', Times, serif;
  color:rgb(56, 59, 255);
  font-size: 50px;
  font-weight: bolder;
  margin: 0;
  
  
}

.me-description{
  font-family:Georgia, 'Times New Roman', Times, serif;
  font-size: 30px;
  font-weight: bolder;

}


@media (max-width: 768px) {
  .face {
    margin-top: 10px;
    width:350px ;
    height: auto;
  }
}

@media (max-width: 768px) {
  .me-title {
    font-size: 50px;
  }
}

@media (max-width: 768px) {
  .me-title2 {
    font-size: 30px;
  }
}

@media (max-width: 768px) {
  .me-description {
    font-size: 20px;
    margin: 20px;
  }
}





@media (max-width: 768px) {
  .hero-container {
    display: inline;
   
    
  }
}

@media (max-width: 768px) {
  .me-container {
    margin-bottom: 70px;
    
  }
}





/* Projects */

.project-link{
  color: rgb(56, 59, 255);
  font-family:Georgia, 'Times New Roman', Times, serif;
  font-size: 30px;
}

.project-title{
  font-family:Georgia, 'Times New Roman', Times, serif;
  color:rgb(56, 59, 255);
  font-size: 70px;
  font-weight: bolder;
  text-decoration: underline;
  
  
}
.displays {
  display: flex;
  
}

.project {
  width: 100%;
  text-align: center;
}


@media (max-width: 768px) {
  .displays {
    display: block;
  }
}




.solopro{
  width: 100%;
  max-width: 800px;
  height: 400px;
  border-radius: 20px;
  border: 5px solid rgb(124, 75, 231);  
  margin: 10px;
  box-shadow: 20px 15px rgb(165, 165, 165);
}

.grouppro{
  width: 100%;
  max-width: 800px;
  height: 400px;
  border-radius: 20px;
  border: 5px solid rgb(124, 75, 231);  
  margin: 10px;
  box-shadow: 20px 15px rgb(165, 165, 165);
}

@media (max-width: 768px) {
  .solopro {
    width: 300px;
    height: auto;
  }
}

@media (max-width: 768px) {
  .grouppro {
    width: 300px;
    height: auto;
  }
}



.title{
  color:rgb(56, 59, 255);
  font-family: Georgia, 'Times New Roman', Times, serif;
  font-size: 40px;
  font-weight: bolder;
  
}

.description{
  color: rgb(0, 0, 0);
  font-family: Georgia, 'Times New Roman', Times, serif;
  font-size: 25px;

}

@media (max-width: 768px) {
  .project-title {
    font-size: 30px;
    
  }
}

@media (max-width: 768px) {
  .title {
    font-size: 24px;
    text-align: center;
  }

  .description {
    font-size: 16px;
    text-align: center;
  }
}

@media (max-width: 768px) {
  .project-link {
    font-size: 16px;
    
  }
}
















/* Languages/Tools */
.tools-container{
  margin-top: 120px;
 
}
.tools-title{
  color: rgb(56, 59, 255);
  font-weight: bolder;
  font-size: 50px;
  font-family:Georgia, 'Times New Roman', Times, serif;
  text-decoration: underline;
}
.list {
  display: flex;
  list-style: none;
  justify-content: center;
  font-size: 30px;
  color: white;
  
}

.list li {
  margin: 10px;
  padding: 10px;
  border: 1px solid black;
  border-radius: 5px;
  background-color: rgb(56, 59, 255);
  box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.541), 0 15px 20px 0 rgba(4, 0, 0, 0.19);
}



@media (max-width: 768px) {
  .tools-title {
    font-size: 24px;
    text-align: center;
   
    
  }
}

@media (max-width: 768px) {
  .list {
    display: grid;
    margin: 0;
    padding: 0;
    
  
   
    
  }


}




/* About me */
.about-container{
  margin-top: 70px;
  
}


.about-title{
 text-align: left;
 font-size: 50px;
 color: rgb(56, 59, 255);
 font-family:Georgia, 'Times New Roman', Times, serif;
 text-decoration: underline;
 font-weight: bolder;
}

.me{
  height: 400px;
  border-radius: 20px;
  border: 5px solid rgb(102, 88, 255);  
  box-shadow: 20px 15px rgb(165, 165, 165);
}


.about-description{
 justify-content: center;
 margin-top: 20px; 
 font-size: 25px;
 font-family:Georgia, 'Times New Roman', Times, serif;
 

 
}

.img-des{
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 30px;
  
}

@media (max-width: 768px) {
  .about-title {
    font-size: 24px;
    text-align: center;
   
    
  }
}


@media (max-width: 768px) {
  .me {
    width: 200px;
    height: auto;
   
    
  }
}


@media (max-width: 768px) {
  .about-description {
   font-size: 16px;
   
    
  }
}

/* contact me */

.contact-container{
  background-color: rgba(80, 60, 255, 0.989);
  width:100% ;
}

.contact-title{
  color: white;
  font-size: 30px;
  
}
.email{
  background-color: rgb(255, 255, 255);
  height: 100px;
  border-radius: 20px;
  box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.541), 0 15px 20px 0 rgba(4, 0, 0, 0.19);
  border: 3px solid rgb(220, 183, 0);
}

.link{
  background-color: rgb(253, 255, 255);
  height: 100px;
  border-radius: 20px;
  box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.541), 0 15px 20px 0 rgba(4, 0, 0, 0.19);
  border: 3px solid rgb(0, 195, 255);
}

.insta{
  background-color: rgb(252, 255, 255);
  height: 100px;
  border-radius: 20px;
  box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.541), 0 15px 20px 0 rgba(4, 0, 0, 0.19);
  border: 3px solid rgb(255, 47, 0);
}

.git{
  background-color: rgb(254, 255, 255);
  height: 100px;
  border-radius: 20px;
  box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.541), 0 15px 20px 0 rgba(4, 0, 0, 0.19);
  border: 3px solid rgb(0, 255, 55);
}

.my-socials {
  display: flex;
  justify-content: center;
  
  
}

.my-socials img {
  margin: 0 10px; 
}

@media (max-width: 768px) {
  .email {
    width:50px;
    height: auto;
    
  }
}

@media (max-width: 768px) {
  .link {
    width:50px;
    height: auto;
    
  }
}

@media (max-width: 768px) {
  .insta {
    width:50px;
    height: auto;
  }
}

@media (max-width: 768px) {
  .git {
    width:50px;
    height: auto;
  }
}

@media (max-width: 768px) {
  .my-socials img {
    position: relative;
    bottom: 25px;
  }
}